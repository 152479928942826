import API from "@/api";
import { socket } from "@/socket";
import {
  getDataTimeString,
  getPeerUUID,
  loadDevice,
} from "@/package/helpers/webinar";

const state = {
  state: "init",
  webinar: "",
  token: "",

  messages: [],
  userName: null,
  userId: null,
  users: [],
  usersCounts: 0,

  device: null,
  consumerTransport: null,

  cameraStream: null,
  cameraStreamId: null,
  audioStream: null,
  screenStream: null,
  screenStreamId: null,

  isAudioAllowed: false,
  isEnumerateAudioDevices: false,
  audioDevices: [],
  selectedAudioDevice: null,
};

const mutations = {
  SET_STATE(store, payload) {
    store[payload.state] = payload.value;
  },

  SET_DEVICES(store, payload) {
    store[payload.state].push(payload.value);
  },

  SET_WEBINAR_TOKEN(store, payload) {
    store.token = payload;
  },
  SET_WEBINAR_USERS(store, payload) {
    store.users = JSON.parse(payload);
    store.usersCounts = store.users.length;
  },
  SET_WEBINAR_MESSAGE(store, payload) {
    let data = payload;
    const date = new Date();
    data.time = `${date.getHours()}:${
      date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
    }`;
    store.messages.unshift(data);
  },

  CLEAR_WEBINAR(store) {
    store.state = "init";
    store.webinar = "";
    store.token = "";
    store.messages = [];
    store.userName = null;
    store.userId = null;
    store.users = [];
    store.usersCounts = 0;
    store.device = null;
    store.consumerTransport = null;
    store.cameraStream = null;
    store.cameraStreamId = null;
    store.audioStream = null;
    store.screenStream = null;
    store.screenStreamId = null;
  },
};

const actions = {
  getWebinarInformation({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/users/webinars/${data}`)
        .then((response) => {
          commit("SET_STATE", { state: "webinar", value: response.data.data });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  generateWebinarToken({ commit, dispatch, state }, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/users/webinars/${data.roomId}/token`)
        .then((response) => {
          commit("SET_WEBINAR_TOKEN", response.data.token);
          socket.emit(
            "createRoom",
            {
              room_id: state.webinar.external_id,
            },
            () => {
              if (!state.userName) {
                commit("SET_STATE", { state: "userName", value: data.name });
              }

              commit("SET_STATE", { state: "userId", value: socket.id });

              dispatch("joinRoom");
            }
          );

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async joinRoom({ state, dispatch, commit }) {
    socket.emit(
      "join",
      {
        room_id: state.webinar.external_id,
        peer_info: {
          join_data_time: getDataTimeString(),
          peer_uuid: getPeerUUID(),
          peer_id: state.userId,
          peer_name: state.userName,
          peer_token: state.token,
          peer_presenter: false,
        },
      },
      async (response) => {
        if (response === "invalid") {
          commit(
            "system/SET_NOTIFICATION",
            {
              type: "error",
              name: "Что-то пошло не так",
              description: "Неверный индификатор комнаты",
            },
            { root: true }
          );
          return;
        }
        if (response === "notAllowed") {
          console.log(
            "00-WARNING ----> Room is Unauthorized for current user, please provide a valid room name for this user"
          );
          return;
        }
        if (response === "unauthorized") {
          commit(
            "system/SET_NOTIFICATION",
            {
              type: "error",
              name: "Что-то пошло не так",
              description: "Не удалось авторизоваться в комнате",
            },
            { root: true }
          );
          return;
        }
        if (response === "isLocked") {
          console.log(
            "00-WARNING ----> Room is Locked, Try to unlock by the password"
          );
          return;
        }
        if (response === "isLobby") {
          console.log(
            "00-WARNING ----> Room Lobby Enabled, Wait to confirm my join"
          );
          return;
        }
        if (response === "isBanned") {
          console.log("00-WARNING ----> You are Banned from the Room!");
          return;
        }

        document.getElementById("usedesk-messenger").style.display = "none";

        commit("SET_STATE", { state: "state", value: "room" });

        dispatch("initSockets");
        dispatch("getRoomInfo");

        socket.emit("getRouterRtpCapabilities", {}, async (response) => {
          let routerRtpCapabilities = response;

          routerRtpCapabilities.headerExtensions =
            routerRtpCapabilities.headerExtensions.filter(
              (ext) => ext.uri !== "urn:3gpp:video-orientation"
            );

          await loadDevice(routerRtpCapabilities).then(async (device) => {
            commit("SET_STATE", { state: "device", value: device });
            await dispatch("initTransfers");
          });
        });
      }
    );
  },

  getRoomInfo({ commit }) {
    socket.emit("getRoomInfo", {}, (room) => {
      commit("SET_WEBINAR_USERS", room.peers);
    });
  },

  async initEnumerateAudioDevices({ dispatch, commit }) {
    await navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(async (stream) => {
        await dispatch("enumerateAudioDevices", stream);
        commit("SET_STATE", { state: "isAudioAllowed", value: true });
      })
      .catch(() => {
        commit("SET_STATE", { state: "isAudioAllowed", value: false });
      });
  },

  async enumerateAudioDevices({ commit }) {
    await navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        devices.forEach(async (device) => {
          if (device.kind === "audiooutput") {
            commit("SET_DEVICES", { state: "audioDevices", value: device });
          }

          if (state.audioDevices.length) {
            commit("SET_STATE", {
              state: "selectedAudioDevice",
              value: state.audioDevices[0].deviceId,
            });
          } else {
            commit("SET_STATE", { state: "isAudioAllowed", value: false });
          }
        });
      })
      .then(async () => {
        commit("SET_STATE", { state: "isEnumerateAudioDevices", value: true });
      });
  },

  async initTransfers({ commit, state }) {
    socket.emit(
      "createWebRtcTransport",
      {
        forceTcp: false,
      },
      (response) => {
        if (response.error) {
          return;
        }

        commit("SET_STATE", {
          state: "consumerTransport",
          value: state.device.createRecvTransport(response),
        });

        socket.emit("getProducers");

        state.consumerTransport.on(
          "connect",
          async ({ dtlsParameters }, callback) => {
            try {
              await socket.emit(
                "connectTransport",
                {
                  transport_id: state.consumerTransport.id,
                  dtlsParameters,
                },
                () => {
                  callback();
                }
              );
            } catch (e) {
              console.log(e);
            }
          }
        );

        state.consumerTransport.on("connectionstatechange", (state) => {
          switch (state) {
            case "failed":
              state.consumerTransport.close();

              break;
          }
        });
      }
    );
  },

  initSockets({ dispatch }) {
    socket.on("message", (data) => {
      dispatch("messageHandle", data);
    });
    socket.on("refreshParticipantsCount", (data) => {
      dispatch("handleRefreshParticipantsCount", data);
    });
    socket.on("removeMe", (data) => {
      dispatch("handleRemoveMe", data);
    });
    socket.on("updatePeerInfo", (data) => {
      dispatch("handleUpdatePeerInfo", data);
    });

    socket.on("newProducers", (data) => {
      dispatch("handleNewProducers", data);
    });

    socket.on("consumerClosed", (data) => {
      dispatch("handleСonsumerClosed", data);
    });

    socket.on("cmd", (data) => {
      dispatch("handleСmd", data);
    });
    socket.on("user_joined", () => {
      dispatch("getRoomInfo");
    });
  },

  async handleСmd({ commit, dispatch, state }, data) {
    if (data.type === "ejectAll") {
      await state.consumerTransport.close();
      await socket.off("disconnect", () => {});
      await socket.off("newProducers", () => {});
      await socket.off("consumerClosed", () => {});
      await dispatch("getWebinarInformation", state.webinar.id).then(() => {
        commit("SET_STATE", { state: "state", value: "ended" });
      });
    }
  },

  handleRefreshParticipantsCount({ commit }, data) {
    commit("SET_STATE", { state: "usersCounts", value: data.peer_counts });
  },

  handleRemoveMe({ dispatch }, data) {
    dispatch("handleRefreshParticipantsCount", data);
    dispatch("getRoomInfo");
  },

  handleUpdatePeerInfo({ dispatch }) {
    dispatch("getRoomInfo");
  },

  handleСonsumerClosed({ commit, state }, data) {
    switch (data.consumer_kind) {
      case "video":
        if (state.cameraStreamId === data.consumer_id) {
          commit("SET_STATE", { state: "cameraStream", value: null });
          commit("SET_STATE", { state: "cameraStreamId", value: null });
          return;
        }
        if (state.screenStreamId === data.consumer_id) {
          commit("SET_STATE", { state: "screenStream", value: null });
          commit("SET_STATE", { state: "screenStreamId", value: null });
          return;
        }
        break;
    }
  },

  async sendMessage({ commit, state }, message) {
    const data = {
      room_id: state.webinar.external_id,
      peer_name: state.userName,
      peer_id: state.userId,
      to_peer_id: "all",
      to_peer_name: "all",
      peer_msg: message,
    };

    socket.emit("message", data, (response) => {
      if (response.data === "ok") {
        commit("SET_WEBINAR_MESSAGE", data);
      }
    });
  },

  messageHandle({ commit }, data) {
    commit("SET_WEBINAR_MESSAGE", data);
  },

  async handleNewProducers({ dispatch }, data) {
    if (data.length > 0) {
      for (let { producer_id, peer_name, peer_info, type } of data) {
        await dispatch("consume", { producer_id, peer_name, peer_info, type });
      }
    }
  },

  async consume({ dispatch }, data) {
    try {
      const { consumer, stream } = await dispatch("getConsumeStream", {
        producerId: data.producer_id,
        peer_id: data.peer_info.peer_id,
        type: data.type,
      });

      try {
        socket.emit(
          "resumeConsumer",
          { consumer_id: consumer.id },
          (response) => {
            console.log("Consumer resumed", response);
          }
        );
      } catch (error) {
        console.error("Error resuming consumer", error);
      }

      consumer.on("trackended", () => {
        // this.removeConsumer(consumer.id, consumer.kind);
      });

      consumer.on("transportclose", () => {
        // this.removeConsumer(consumer.id, consumer.kind);
      });

      dispatch("handleConsumer", {
        consumerId: consumer.id,
        type: data.type,
        stream: stream,
        peer_name: data.peer_info.peer_name,
        peer_info: data.peer_info,
      });
    } catch (e) {
      console.log(e);
    }
  },

  async getConsumeStream({ state }, payload) {
    const { rtpCapabilities } = state.device;
    const producerId = payload.producerId;
    const consumerTransportId = state.consumerTransport.id;

    return new Promise((resolve) => {
      socket.emit(
        "consume",
        {
          rtpCapabilities,
          producerId,
          consumerTransportId,
        },
        (response) => {
          const { id, kind, rtpParameters } = response;
          const streamId =
            payload.peer_id +
            (payload.type === "screen" ? "-screen-sharing" : "-mic-webcam");

          state.consumerTransport
            .consume({
              id,
              producerId,
              kind,
              rtpParameters,
              streamId,
            })
            .then((consumer) => {
              const stream = new MediaStream();
              stream.addTrack(consumer.track);

              resolve({
                consumer,
                stream,
                kind,
              });
            });
        }
      );
    });
  },

  async handleConsumer({ commit }, data) {
    switch (data.type) {
      case "videoType":
        commit("SET_STATE", { state: "cameraStream", value: data.stream });
        commit("SET_STATE", {
          state: "cameraStreamId",
          value: data.consumerId,
        });
        break;
      case "audioType":
        commit("SET_STATE", { state: "audioStream", value: data.stream });
        break;
      case "screenType":
        commit("SET_STATE", { state: "screenStream", value: data.stream });
        commit("SET_STATE", {
          state: "screenStreamId",
          value: data.consumerId,
        });
        break;
    }
  },

  async exitWebinarRoom({ commit, state }) {
    socket.emit("exitRoom", {}, () => {});
    state.consumerTransport.close();
    socket.off("disconnect", {}, () => {});
    socket.off("newProducers", {}, () => {});
    socket.off("consumerClosed", {}, () => {});
    commit("SET_STATE", { state: "state", value: "exit" });
  },

  clearWebinar({ commit }) {
    commit("CLEAR_WEBINAR");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
